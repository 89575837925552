<template>
  <div v-loading="loadingFlag" class="amount-hxqy-detail">
    <div class="title">
      额度信息
    </div>
    <ul class="info-list clear">
      <li class="flex-box">
        <span class="label">核心企业：</span><span class="info flex1">{{ detailList.coreName }}</span>
      </li>
      <li class="flex-box">
        <span class="label">资金方：</span><span class="info flex1">{{ detailList.bankName }}</span>
      </li>
      <li class="flex-box">
        <span class="label">所属集团：</span><span class="info flex1">{{ detailList.groupName }}</span>
      </li>
    </ul>
    <div class="title">
      核心企业额度
    </div>
    <ul class="info-list clear">
      <li class="flex-box">
        <span class="label">总额度(元)：</span><span
          class="info flex1"
        >{{ detailList.quotaSum|formatMoney }}</span>
      </li>
      <li class="flex-box">
        <span class="label">已用额度(元)：</span><span
          class="info flex1"
        >{{ detailList.quotaUsed|formatMoney }}</span>
      </li>
      <li class="flex-box">
        <span class="label">冻结额度(元)：</span><span
          class="info flex1"
        >{{ detailList.quotaDisabled|formatMoney }}</span>
      </li>
      <li class="flex-box">
        <span class="label">可用额度(元)：</span><span
          class="info flex1"
        >{{ detailList.quotaAbled|formatMoney }}</span>
      </li>
      <li class="flex-box">
        <span class="label">核心企业额度到期日：</span><span
          class="info flex1"
        >{{ detailList.quotaDue|date }}</span>
      </li>
      <li class="flex-box">
        <span class="label">额度状态：</span><span class="info flex1">{{ detailList.quotaStat==0?'有效':'失效' }}</span>
      </li>
      <li class="flex-box">
        <span class="label">供应商额度(元)：</span><span
          class="info flex1"
        >{{ detailList.defCustQuoat|formatMoney }}</span>
      </li>
    </ul>
    <div class="title">
      产品专项额度
    </div>
    <div class="table-box">
      <el-table :border="true" :data="detailList.prodList" class="br-table">
        <el-table-column label="产品编码" prop="prodId" min-width="120" />
        <el-table-column label="资金方产品编码" prop="bankProdCode" min-width="120" />
        <el-table-column label="版本" prop="bankVersion" min-width="120" />
        <el-table-column label="产品名称" prop="prodName" min-width="120" />
        <el-table-column label="产品类别" prop="prodCate" min-width="120">
          <template slot-scope="scope">
            {{ $code("proCate",scope.row.prodCate) }}
          </template>
        </el-table-column>
        <el-table-column label="产品品种" prop="prodType" min-width="120">
          <template slot-scope="scope">
            {{ $childrenCode("proCate",scope.row.prodCate,scope.row.prodType) }}
          </template>
        </el-table-column>
        <el-table-column label="产品总额度（元）" prop="quotaProdSum" min-width="120">
          <template slot-scope="scope">
            {{ scope.row.quotaProdSum|formatMoney }}
          </template>
        </el-table-column>
        <el-table-column label="产品已用额度（元）" prop="quotaProdUsed" min-width="130">
          <template slot-scope="scope">
            {{ scope.row.quotaProdUsed|formatMoney }}
          </template>
        </el-table-column>
        <el-table-column label="冻结额度（元）" prop="quotaProdDisabled" min-width="120">
          <template slot-scope="scope">
            {{ scope.row.quotaProdDisabled|formatMoney }}
          </template>
        </el-table-column>
        <el-table-column label="可用额度（元）" prop="quotaProdAbled" min-width="120">
          <template slot-scope="scope">
            {{ scope.row.quotaProdAbled|formatMoney }}
          </template>
        </el-table-column>
        <el-table-column label="操作" width="50" fixed="right">
          <template slot-scope="scope">
            <el-button size="mini" type="text" class="table-detail-button" @click="productDetail(scope.row)">
              详情
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
import { getAmountCoreDetail } from '@/api/tradeFinancing'
// import { formatMoney, date } from '@/utils/filter.js'
export default {
  data() {
    return {
      loadingFlag: false,
      detailList: []
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.loadingFlag = true
      getAmountCoreDetail({ id: this.$route.query.id }, res => {
        this.loadingFlag = false
        this.detailList = res.data
      }, () => {
        this.loadingFlag = false
      })
    },
    // 产品专项额度点详情
    productDetail(row) {
      this.$router.push({
        path: '/productManage/productDetail',
        name: 'productDetail',
        query: {
          type: '1',
          proId: row.prodId
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .amount-hxqy-detail {
    background-color: #fff;
    padding: 10px 20px;

    .title {
      line-height: 40px;
      font-weight: 600;
      font-size: 16px;
    }

    .info-list {
      padding: 10px;

      li {
        float: left;
        width: 32%;
        line-height: 30px;
        font-size: 14px;
        padding: 0 4px;

        .label {

        }

        .info {
          color: #606266;
        }
      }
    }

    .table-box {
      padding: 10px;
    }
  }
</style>

